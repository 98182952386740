import { nanoid } from "nanoid";

export const HourTypes = {
  Leitung: 'leitung',
  Inklusion: 'inklusion',
  Fachkraft: 'fachkraft',
  Vertretung: 'vertretung'
}

export class Hour{
  static dateCache = {};
  static getDate(date){
    if(!Hour.dateCache[date])
      Hour.dateCache[date] = new Date(date);
    return Hour.dateCache[date];
  }
  static dateToString(date){
    if(!date)
      return null;
    if(typeof date === "string")
      date = new Date(date);

    const month   = date.getMonth() + 1; // months from 1-12
    const day     = date.getDate();
    const year    = date.getFullYear();

    const pMonth        = month.toString().padStart(2,"0");
    const pDay          = day.toString().padStart(2,"0");
    const newPaddedDate = `${year}-${pMonth}-${pDay}`;
    return newPaddedDate;


  }

  constructor(type, hours, startedAt, endedAt, reference, id){
    this.id = id ||  nanoid()
    this.type=type;
    this.startedAt = startedAt;
    this.endedAt = endedAt;
    this.hours = hours;
    if(reference){
      this.reference = reference;
    }
  }

  asObject(){
    return {
      id: this.id,
      type: this.type,
      startedAt: this.startedAt,
      endedAt: this.endedAt,
      hours: this.hours,
      reference: this.reference?this.reference.asObject():null
    }
  }

}