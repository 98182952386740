import { nanoid } from "nanoid";

export class Employee {
  static instances = 0;
  constructor(name, id, sort) {
    Employee.instances++;
    this.sort = sort || Employee.instances
    this.name = name;
    this.id = id || Employee.instances;
    this.hours = [];
  }

  asObject(){
    return {
      sort: this.sort,
      id: this.id,
      name: this.name,
      hours: this.hours.map(h=>h.asObject())
    }
  }

}