import classes from './EmployeeModal.module.css';
import { useEffect, useState } from 'react';
import { Employee } from "./../../_models/Employee.js";
import { Hour, HourTypes } from "./../../_models/Hours.js";
import { useDispatch, useSelector } from 'react-redux';
import {  selectEmployeeId, setEmployee, setSelectedHourId } from '../../_store/appSlice';
//import DatePicker from 'react-date-picker';
//import 'react-date-picker/dist/DatePicker.css';
//import 'react-calendar/dist/Calendar.css';
import { GetLang } from '../../_models/Lang';
import EmployeeHour from './EmployeeHour/EmployeeHour';
function EmployeeModal() {
  const dispatch = useDispatch();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const employees = useSelector((state) => state.app.employees);
  const selectedEmployeeId =  useSelector((state) => state.app.selectedEmployeeId)
  const employee = employees.find((employee)=>employee.id === selectedEmployeeId);

  const hourChanged = (hour) => {
    let empl = JSON.parse(JSON.stringify(selectedEmployee));
    let h = empl.hours.find((h)=>h.id === hour.id);
    if(h){
      empl.hours[empl.hours.indexOf(h)] = hour;
      setSelectedEmployee(empl);
    }
  }

  const hourDeleted = (hour) => {
    let empl = JSON.parse(JSON.stringify(selectedEmployee));
    let h = empl.hours.find((h)=>h.id === hour.id);
    let index = empl.hours.indexOf(h);
    if(index>-1)
    {
      empl.hours.splice(index, 1);
      setSelectedEmployee(empl);
    }
  }

  const addHour = (hour) => {
    let empl = JSON.parse(JSON.stringify(selectedEmployee));
    empl.hours.push(hour.asObject());
    setSelectedEmployee(empl);
  }

  const nameChanged = (name) => {
    let empl = JSON.parse(JSON.stringify(selectedEmployee));
    empl.name = name;
    setSelectedEmployee(empl);
  }

  const sortChanged = (sort) => {
    let empl = JSON.parse(JSON.stringify(selectedEmployee));
    empl.sort = parseInt(sort);
    setSelectedEmployee(empl);
  }


  const save = () => {
    dispatch(setEmployee(selectedEmployee));
    close();
  }

  const close = () => {
    dispatch(selectEmployeeId(null));
  }

  const keydown = (e) => {
    if(e.key === "Escape")
      close();
  }

  useEffect(() => {

    let emp = employees.find((e)=>e.id === employee?.id);

    setSelectedEmployee(emp?JSON.parse(JSON.stringify(emp)):null);

    document.addEventListener("keydown", keydown);
    return ()=>{document.removeEventListener("keydown", keydown);}
  },[employee]);

  if(!selectedEmployee || !employee)
    return null;

  let hours = [...selectedEmployee.hours];
  hours.sort((a,b)=>a.startedAt > b.startedAt ? 1 : -1);
  return (
    <div className={classes.root}>
      <div className={classes.bg} onClick={close}></div>
      <div className={classes.content}>
        <div className={classes.header}>
          {employee.name}
          <div className="close btn btn-secondary" onClick={close}>X</div>
        </div>
        <div className={classes.body}>
          <div className="d-flex" style={{justifyContent:'space-between', marginRight:'4rem'}}>
          <input type="text" placeholder="Name" className={"form-control"} value={selectedEmployee.name} onChange={(e)=>{nameChanged(e.target.value)}} />
          <input type="numer" placeholder="Sortierung" className={"form-control"} value={selectedEmployee.sort || 0} onChange={(e)=>{sortChanged(e.target.value)}} />
          </div>
          <div>
            <h3>Zeiten</h3>
            {hours.map((hour)=>
              <EmployeeHour hour={hour} key={hour.id} employee={selectedEmployee} onChanged={hourChanged} onDeleted={hourDeleted} />
            )
          }
          </div>
          <div className="btn btn-primary"><i className='bi bi-plus-lg' onClick={()=>addHour(new Hour(HourTypes.Fachkraft, 0, Hour.dateToString(new Date()), null))}></i></div>
        </div>
        <div className={classes.footer}>
          <div className="btn btn-secondary" onClick={()=>close()}>{GetLang("abbrechen")}</div>
          <div className="btn btn-success" onClick={()=>save()}>{GetLang("speichern")}</div>
        </div>
      </div>

    </div>
  );
}

export default EmployeeModal;
