import classes from './EmployeeHour.module.css';
import { useEffect, useState } from 'react';
import { Employee } from "./../../../_models/Employee.js";
import { Hour, HourTypes } from "./../../../_models/Hours.js";
import { useDispatch, useSelector } from 'react-redux';
import {  selectEmployeeId, setEmployee, setSelectedHourId } from '../../../_store/appSlice';
import { GetLang } from '../../../_models/Lang';


function EmployeeHour({hour, employee, onChanged, onDeleted}) {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.app.employees);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedSubstitudeHour, setSelectedSubstitudeHour] = useState(null);


  const onChangeStart = (value, which) => {
    let h = JSON.parse(JSON.stringify(hour));

    if(h)
    {
      if(which === "start")
        h.startedAt = value?Hour.dateToString(value):null;
      else if(which === "end")
        h.endedAt = value?Hour.dateToString(value):null;
      else if(which === "hours")
        h.hours = parseFloat(value);
      else if(which === "type")
        h.type = value;

      onChanged && onChanged(h);
      //dispatch(setEmployee(empl));
    }
  }

  const deleteHour = (hour) => {
    onDeleted && onDeleted(hour);
  }

  const onChangePerson = (id)=>{
    setSelectedPerson(getPersonById(parseInt(id)));
  }

  const onChangeSubstutionHour = (id)=>{

    let substitude =  selectedPerson?.hours.find((h)=>h.id === id);
    let h = JSON.parse(JSON.stringify(hour));
    h.reference = substitude;

    setSelectedSubstitudeHour(substitude);
    onChanged && onChanged(h);
  }

  const getPersonByHour = (hour_id) => {
    for(let i = 0; i<employees.length; i++){
      if(employees[i].hours.find((h)=>h.id === hour_id))
        return employees[i];
    }
    return null;
  }

  const getPersonById = (id) => {
    return employees.find((e)=>e.id === id);
  }

  useEffect(() => {

    if(selectedPerson?.hours.length===1){
      //setSelectedSubstitudeHour(selectedPerson?.hours[0]);
      onChangeSubstutionHour(selectedPerson?.hours[0].id)
    }
    else if(selectedPerson?.hours.find(h => h.id === hour.reference?.id))
      setSelectedSubstitudeHour(selectedPerson?.hours.find(h => h.id ===hour.reference?.id));
    else
      setSelectedSubstitudeHour(selectedPerson?.hours?.length>0?selectedPerson.hours[0]:null);

  },[selectedPerson]);

  useEffect(() => {
    if(hour?.type === HourTypes.Vertretung)
    {
      setSelectedPerson(getPersonByHour(hour.reference?.id))
    }else
      setSelectedPerson(null);

  },[hour]);

  if(!employee)
    return null;

  return (
    <div className={classes.root}>
      <select value={hour.type} onChange={(e)=>onChangeStart(e.target.value, "type")}>
          <option value={HourTypes.Fachkraft}>{GetLang(HourTypes.Fachkraft)}</option>
          <option value={HourTypes.Leitung}>{GetLang(HourTypes.Leitung)}</option>
          <option value={HourTypes.Inklusion}>{GetLang(HourTypes.Inklusion)}</option>
          <option value={HourTypes.Vertretung}>{GetLang(HourTypes.Vertretung)}</option>
      </select>
      {hour.type === HourTypes.Vertretung &&

          <select value={selectedPerson?.id} onChange={(e)=>onChangePerson(e.target.value)}>
            <option value={null}>-</option>
            {employees.map((empl)=><option key={empl.id} value={empl.id}>{empl.name}</option>)}
          </select>


      }
      {selectedPerson &&
        <select value={hour.reference?.id} onChange={(e)=>onChangeSubstutionHour(e.target.value)}>
          {selectedPerson?.hours.map((empl)=><option key={empl.id} value={empl.id}>{GetLang(empl.type)}</option>)}
        </select>
      }

      <input type="number" placeholder="Stunden" className={"form-control"} defaultValue={hour.hours} onChange={(e)=>{onChangeStart( e.target.value, "hours")}} />

      <input
          aria-label="Start"
          type="date"
          onChange={(e)=>{
            let d = new Date(e.target.value);
            if(d.getFullYear() < 1000)
              return;
            if(isNaN(d))
              d = null;
            onChangeStart(Hour.dateToString(d), "start")
          }}

          defaultValue={hour.startedAt}

          />

      <input
          aria-label="Start"
          type="date"
          onChange={(e)=>{
            let d = new Date(e.target.value);
            if(d.getFullYear() < 1000)
              return;
            if(isNaN(d))
              d = null;
            onChangeStart(Hour.dateToString(d), "end")
          }}

          defaultValue={hour.endedAt}
          />

      <i className='bi bi-trash' onClick={()=>deleteHour(hour)}></i>
    </div>
  );
}

export default EmployeeHour;
