import './App.css';
import classes from './App.module.css';
import { useEffect, useRef, useState } from 'react';
import { Employee } from "./_models/Employee.js";
import { Hour, HourTypes } from "./_models/Hours.js";
import EmployeeView from './_components/EmployeeView';
import { addNewEmployee, exportEmployees, importEmployees, setActualDate, setEmployees } from './_store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeModal from './_components/EmployeeModal/EmployeeModal';
import { GetLang } from './_models/Lang';

let dragData = {

}
function App() {
  const dispatch = useDispatch();
  const thumbRef = useRef(null);
  const employees = useSelector((state) => state.app.employees);
  const [date, setDate] = useState(Date.now());
  useEffect(() => {
    if(localStorage.getItem("employees") && JSON.parse(localStorage.getItem("employees")))
      dispatch(setEmployees(JSON.parse(localStorage.getItem("employees"))));
  },[]);

  let _actualDate = new Date(date);

  //console.log(Hour.getDate("2023-02-02") >= dateFrom);
  //#region timeline dates and steps

  let minDate = new Date("3000-01-01").getTime();
  let maxDate = new Date().getTime();
  let steps = []
  employees.forEach((employee)=>{
    employee.hours.forEach((hour)=>{
      const started = hour.startedAt != null ? Hour.getDate(hour.startedAt).getTime():0;
      const ended = hour.endedAt != null? Hour.getDate(hour.endedAt).getTime():0;
      if(started!=0)
        steps.push({val:started, ref:hour});
      if(ended!=0)
        steps.push({val:ended, ref:hour});
      if(started>0 && started < minDate){

        minDate = started;
      }
      if(ended>0 && ended > maxDate)
        maxDate = ended;
    });
  });

  minDate = minDate-1000*60*60*24;
  maxDate = maxDate+1000*60*60*24;
  const dateDif = maxDate - minDate;
  steps.sort((a,b)=>a-b);
  steps.forEach((step, index)=>{
    step.percent = (step.val - minDate) / dateDif;
  });

  //#endregion timeline dates and steps

  //#region filter employess
  let filteredEmployees = [];
  employees.forEach((employee)=>{
    let emp = JSON.parse(JSON.stringify({...employee}));
    emp.hours = emp.hours.filter((hour)=>Hour.getDate(hour.startedAt) <= _actualDate && (!hour.endedAt || Hour.getDate(hour.endedAt) >= _actualDate));
    emp.hours.forEach((hour)=>{

      if(hour.substituted)
      {
        hour.substituted = hour.substituted.filter((sub)=>Hour.getDate(sub.startedAt) <= _actualDate && (!sub.endedAt || Hour.getDate(sub.endedAt) >= _actualDate));
      }
    });
    filteredEmployees.push(emp);

  });
  //#endregion filter employess


  //#region timeline drag

  const startDrag = (e) => {
    console.log("start drag", e);
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', stopDrag);

    dragData.startX = e.clientX;
    dragData.startLeft = thumbRef.current.offsetLeft;

    const rect = thumbRef.current.parentNode.getBoundingClientRect();
    dragData.left = rect.left;
    dragData.width = rect.width-30;
    dragData.right = rect.right;
  }

  const onDrag = (e) => {
    //console.log("drag", e);
    let dif = (e.clientX - dragData.startX);

    const percent = (Math.min(Math.max(dragData.startLeft + dif, 0), dragData.width)) / dragData.width;

   // thumbRef.current.style.left = (percent*dragData.width)+"px";
    setDate(minDate+dateDif*percent);
  }
  const stopDrag = (e) => {
    console.log("stop drag", e.clientX, dragData);

    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
  }
  //#endregion timeline drag

  const _import = () => {
    var upload = document.createElement('input');
    upload.type = 'file';
    upload.addEventListener('change', function() {
      if (upload.files.length > 0)
      {
        var reader = new FileReader(); // File reader to read the file
        reader.addEventListener('load', function() {
          var result = JSON.parse(reader.result); // Parse the result into an object
          if(result?.appKey != "KEO")
          {
            alert("Falsche Datei");
            return;
          }
          dispatch(importEmployees(result));
        });

        reader.readAsText(upload.files[0]); // Read the uploaded file
      }
    });
    upload.click();
  }

  //console.log(new Date(minDate), new Date(maxDate), steps);

  //console.log(filteredEmployees);

  let hourSums = {[HourTypes.Fachkraft]:0, [HourTypes.Inklusion]:0, [HourTypes.Leitung]:0, [HourTypes.Vertretung]:0};

  filteredEmployees.forEach((employee)=>{
    employee.hours.forEach((hour)=>{
      hourSums[hour.type] += hour.hours;
    });
  });

  filteredEmployees.forEach((employee)=>{
    let hours = employee.hours.reduce((a,b)=>a+b.hours, 0);

    if(hours <= 0)
      employee.unactive = true;
  });

  const hasContent = employees.length > 0;
  return (
    <div className={classes.App}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <h1>{_actualDate.toLocaleDateString()}</h1>
        </div>
        <div className={classes.actionBar}>
          <div className='btn btn-secondary' onClick={_import}>Importieren</div>
          <div className='btn btn-primary' onClick={()=>dispatch(exportEmployees())}>Exportieren</div>
        </div>
      </div>
      <div>
      {hasContent && <div className={classes.header}>
          <div className={classes.headerLeft}>
            <div className={classes.hourSums}>
              {GetLang(HourTypes.Fachkraft)}: {hourSums[HourTypes.Fachkraft]}<br/>
              {GetLang(HourTypes.Inklusion)}: {hourSums[HourTypes.Inklusion]}<br/>
              {GetLang(HourTypes.Leitung)}: {hourSums[HourTypes.Leitung]}<br/>
            </div>
          </div>
          <div className={classes.headerRight}>
            <div className={classes.timeline}>
              <div className={classes.steps}>
                {steps.map((step, index)=>
                  <div key={index} className={classes.step} style={{left:step.percent * 100 +"%"}}></div>
                )}
              </div>
              <div
                className={classes.thumb}
                onMouseDown={startDrag}
                ref={thumbRef}
                style={{left:`calc(${((date-minDate)/dateDif)} * (100% - 30px))`}}>

              </div>
            </div>
          </div>
        </div>
      }

        <div className={classes.employees}>
          {filteredEmployees?.filter(e=>!e.unactive).map((employee) =>
            <EmployeeView key={employee.id} employee={employee} />
          )}
        </div>
        <div className={classes.employees}>
          {filteredEmployees?.filter(e=>e.unactive).map((employee) =>
            <EmployeeView key={employee.id} employee={employee} />
          )}
        </div>
        <div>
          <br/>
          <div className={classes.addEmployeeBtn +' btn btn-secondary'} onClick={()=>dispatch(addNewEmployee())}><i className='bi bi-plus-lg'></i> Mitarbeiter hinzufügen</div>
        </div>
      </div>
      <div className={classes.footer}>
        <a href="https://www.keo-overath.de/impressum/">Impressum</a> - &copy; 2024 KEO - Elternverein Overath e.V.
      </div>
      <EmployeeModal></EmployeeModal>

    </div>
  );
}

export default App;
