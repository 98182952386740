import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),/*.concat(apiSlice.middleware),*/
  devTools: true,
});