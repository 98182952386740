import { createSlice } from "@reduxjs/toolkit";
import { Hour, HourTypes } from "../_models/Hours";
import { Employee } from "../_models/Employee";


const clearSubstituedHours = (employees) => {
  for(let i=0;i<employees.length; i++){
    for(let j = 0; j<employees[i].hours.length; j++){
      delete employees[i].hours[j].substituted;
    }
   }
}
const clearDeadReferences = (employees) => {
  for(let e=0;e<employees.length; e++){
    let employee = employees[e];
    for(let i = 0; i<employee.hours.length; i++){
      if(employee.hours[i].type === HourTypes.Vertretung){

        let substituteFrom = employees.find((empl)=>{
          return empl.hours.find(h=>h?.id===employee.hours[i].reference?.id);
        });

        if(!substituteFrom)
          employee.hours.splice(i,1);

      }
    }
  }
}

const clearDeepRefs = (employees) => {
  console.log("clearDeepRefs", employees);
  for(let e=0;e<employees.length; e++){
    let employee = employees[e];
    for(let i = 0; i<employee.hours.length; i++){
      if(employee.hours[i].type === HourTypes.Vertretung){
        let substituteFrom = employees.find((empl)=>{
          return empl.hours.find(h=>h?.id===employee.hours[i].reference?.id);
        });

        if(substituteFrom){
          let referenceHour = substituteFrom.hours.find((h)=>h?.id === employee.hours[i].reference?.id);
          for(let i in referenceHour?.substituted){
            let s = {...referenceHour.substituted[i]};
            console.log(s);
            referenceHour.substituted[i] = s;

          }
        }else{
          console.error("No reference found for "+employee.hours[i].reference?.id, employee, employee.hours[i]);
        }

      }
    }
  }

}

const calculateSubstituedHours = (employees) => {
  clearDeadReferences(employees);
  clearSubstituedHours(employees);
// clearDeepRefs(employees);

  for(let e=0;e<employees.length; e++){
    let employee = employees[e];
    for(let i = 0; i<employee.hours.length; i++){
      if(employee.hours[i].type === HourTypes.Vertretung){

        let substituteFrom = employees.find((empl)=>{
          return empl.hours.find(h=>h?.id===employee.hours[i].reference?.id);
        });

        if(substituteFrom){
          let referenceHour = substituteFrom.hours.find((h)=>h?.id === employee.hours[i].reference?.id);
          if(referenceHour){
            if(!referenceHour.substituted)
              referenceHour.substituted = [];
            //stop deeplinks
            let s = JSON.parse(JSON.stringify(employee.hours[i]));
            s.reference = null;
            referenceHour.substituted.push(s);
          }
        }else{
          console.error("No reference found for "+employee.hours[i].reference?.id, employee, employee.hours[i]);
        }

      }
    }
  }
}
const initialState = {
    employees: [],
    selectedHourId:null,
    actualDate:Hour.dateToString(new Date()),
    selectedEmployeeId:null
};



const saveDB = (_employees) => {
  let employees = JSON.parse(JSON.stringify(_employees));
  for(let i = 0; i<employees.length; i++){
    for(let j = 0; j<employees[i].hours.length; j++){
      delete employees[i].hours[j].substituted;
    }
  }

  let last = localStorage.getItem("employees");
  localStorage.setItem("employees", JSON.stringify(employees));
}
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload || [];
      for(let i = 0; i<state.employees.length; i++){
        calculateSubstituedHours(state.employees);
      }
      state.employees.sort((a,b)=>a.sort - b.sort);
      saveDB(state.employees);
    },
    setSelectedHourId: (state, action) => {
      state.selectedHourId = action.payload;
    },
    setActualDate: (state, action) => {
      state.actualDate = typeof(action.payload) === "string" ? action.payload : Hour.dateToString(action.payload);
    },
    selectEmployeeId: (state, action) => {

      state.selectedEmployeeId = action.payload;
    },
    setEmployee: (state, action) => {

      let empl = action.payload;
      if(empl){
        let index = state.employees.findIndex((employee)=>employee.id === empl.id);
        if(index !== -1){

          state.employees[index] = empl;
          calculateSubstituedHours(state.employees);
        }
      }
      state.employees.sort((a,b)=>a.sort - b.sort);

      saveDB(state.employees);

    },

    addNewEmployee: (state) => {
      let newEmployee = new Employee("", state.employees.length+1);
      state.employees.push(newEmployee.asObject());
      state.selectedEmployeeId = newEmployee.id;

      console.log(state.employees, state);

    },
    importEmployees: (state, action) => {

      if(action.payload.appKey != "KEO")
        return;
      state.employees = action.payload.employees || [];
      state.employees.sort((a,b)=>a.sort - b.sort);
      state.import = action.payload.date
      for(let i = 0; i<state.employees.length; i++){
        calculateSubstituedHours(state.employees);
      }

      saveDB(state.employees);
    },
    exportEmployees: (state) => {
      clearSubstituedHours(state.employees);
      console.log(state.employees);

      let payload = {date:Date.now(), employees:[...state.employees], appKey:"KEO" };
      let dataString = JSON.stringify(payload, null, 2);

      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(dataString);

      let dlAnchorElem = document.createElement('a');
      document.body.appendChild(dlAnchorElem);
      dlAnchorElem.setAttribute("href",     dataStr     );
      dlAnchorElem.setAttribute("download", "stunden_export_"+new Date().getTime()+".json");
      dlAnchorElem.click();
      document.body.removeChild(dlAnchorElem);


      calculateSubstituedHours(state.employees);
    }


  },
});

export default appSlice.reducer;
export const { setEmployees, setSelectedHourId, setActualDate, selectEmployeeId, setEmployee, addNewEmployee, importEmployees, exportEmployees } = appSlice.actions;