import classes from './EmployeeView.module.css';
import { useEffect } from 'react';
import { Employee } from "./../_models/Employee.js";
import { Hour, HourTypes } from "./../_models/Hours.js";
import { useDispatch, useSelector } from 'react-redux';
import {  selectEmployeeId, setSelectedHourId } from '../_store/appSlice';
import { GetLang } from '../_models/Lang';

function EmployeeView({employee}) {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.app.employees);
  const selectedHourId = useSelector((state) => state.app.selectedHourId);

  const mouseOver = (hour) =>{

    if(hour?.type === HourTypes.Vertretung && hour.reference){
      dispatch(setSelectedHourId(hour.id))
    }
  }

  const getHourTitleAlt = (hour) =>{
    return GetLang(hour.type) + " " +hour.hours + "Std";
  }
  const getHourTitle = (hour) =>{

    if(hour.type === HourTypes.Vertretung && hour.reference)
      return GetLang(hour.type);
  }
  const mouseOut = (hour) =>{
    dispatch(setSelectedHourId(null))
  }

  let actualHours = employee.hours;
  let totalHours = actualHours.reduce((a,b)=>a+b.hours, 0);
  let substitutedHours = [];

  return (
    <div className={classes.root} id={"employee_"+employee.id} onClick={()=>dispatch(selectEmployeeId(employee.id))}>
      {employee.name}
      <span className={classes.totalHours}>{totalHours}</span>

      <div className={classes.hours}>
      {
        actualHours.map((hour) =>
          <div key={hour.id} className={classes.hour+" "+hour.type + "  " + (selectedHourId === hour.id ? classes.selected : "")} style={{'--width':hour.hours/totalHours}} id={"hours_"+hour.id} onMouseOver={(e)=>mouseOver(hour)} onMouseOut={()=>mouseOut(hour)} title={getHourTitleAlt(hour)}>
            <div className={classes.title}>{getHourTitle(hour)}
            {hour.hours}
            </div>
            <div className={classes.dates}>{hour.startedAt} - {hour.end}</div>

            {hour.substituted &&
            <div className={classes.substitutedHours}>
            {
              hour.substituted.map((substitutedHour, index)=>
                <div
                  id={"substitutedHour_"+substitutedHour.id}
                  className={classes.substitutedHour + "  " + (selectedHourId === substitutedHour.id ? classes.selected : "")}
                  key={substitutedHour.id+"_"+index}
                  style={{'--width':substitutedHour.hours/hour.hours}}
                  onMouseOver={(e)=>mouseOver(substitutedHour)} onMouseOut={()=>mouseOut(substitutedHour)}
                  >

                  </div>
              )
            }
            </div>
            }
          </div>
        )
      }
      </div>
      <div className={classes.actions}>
      <i className={classes.editBtn + ' bi bi-pencil-fill'} onClick={()=>dispatch(selectEmployeeId(employee.id))}></i>
      </div>
    </div>
  );
}

export default EmployeeView;
